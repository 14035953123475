<template>
    <div class="panel has-text-weight-semibold">
            <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                <div class="columns is-mobile">
                    <div class="column is-size-6 has-text-centered">SHIFT REPORT</div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4 has-text-left">
                    <DateRangeSelector
                        :dateRange="salesDateRange"
                        @set-date-range="setDateRange"
                        >
                    </DateRangeSelector>
                </div>              
            </div>
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Sales</p>
                    <p class="is-size-6" :class="{'has-text-danger': totalSalesAmount < 0.00 }"> {{ totalSalesAmount | formatCurrency }}</p>
                    </div>
                </div>  

                                                                                 
            </div>
        </div>
        <br>

        <b-table v-if="records"
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='records'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="salesDate"
            default-sort-direction="asc"
            :show-detail-icon="showDetailIcon"
            detailed
            detail-key="bookNumber"
            @details-open="(row) => $buefy.toast.open(`Showing Sales for ${row.lotteryGames.gameNumber}-${row.bookNumber} - ${row.lotteryGames.name} `)"            
            >

            <template>
                
                <b-table-column class="is-2 has-text-right" field="lotteryGames.gameNumber" label="Game" v-slot="props" sortable sorticon>
                {{ props.row.lotteryGames.gameNumber }}
                </b-table-column>
                
                <b-table-column class="is-2 has-text-right" field="ticketCount" label="Book" v-slot="props" sortable sorticon>
                {{ props.row.bookNumber }}
                </b-table-column>

                <b-table-column class="is-2 has-text-left" field="lotteryGames.name" label="Name" v-slot="props" sortable sorticon>
                {{ props.row.lotteryGames.name }}
                </b-table-column>

                <b-table-column numeric class="is-2 has-text-right" field="ticketCount" label="Tickets in Book" v-slot="props" sortable sorticon>
                {{ props.row.ticketCount }}
                </b-table-column>

                <b-table-column numeric class="is-2 has-text-right" field="lotteryGames.pricePoint" label="Ticket Price" v-slot="props" sortable sorticon>
                {{ props.row.lotteryGames.pricePoint | formatCurrency }}
                </b-table-column>

                <b-table-column numeric class="is-2 has-text-right" field="ticketCount" label="Full Book Value" v-slot="props" sortable sorticon>
                {{ props.row.ticketCount * props.row.lotteryGames.pricePoint  | formatCurrency}}
                </b-table-column>

                <b-table-column numeric class="is-2 has-text-right" field="lastTicketSold" label="Tickets Sold" v-slot="props" sortable sorticon>
                <span v-if="props.row.lastTicketSold">{{ props.row.lastTicketSold }}</span>
                <span v-else>0</span>
                </b-table-column>

                <b-table-column numeric class="is-2 has-text-right" field="ticketCount" label="Tickets Not Sold" v-slot="props" sortable sorticon>
                {{ props.row.ticketCount - props.row.lastTicketSold }}
                </b-table-column>
                <b-table-column numeric class="is-2 has-text-right" field="ticketCount" label="Inventory Value" v-slot="props" sortable sorticon>
                {{ (props.row.ticketCount - props.row.lastTicketSold) *  props.row.lotteryGames.pricePoint | formatCurrency  }}
                </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
              <b-table :data="props.row.lotteryBookSales">
                    <b-table-column field="startingSeq" label="Date" v-slot="props">
                        {{ props.row.salesSummary.closingDate | formatDate}}
                    </b-table-column>
                    <b-table-column field="periodBeginDate" label="Shift Time" v-slot="props">
                        {{ props.row.salesSummary.periodBeginDate |formatDateTime}} - {{ props.row.salesSummary.periodEndDate |formatDateTime}}
                    </b-table-column>
                    <b-table-column numeric field="startingSeq" label="Start Seq" v-slot="props">
                        {{ props.row.startingSeq }}
                    </b-table-column>
                    <b-table-column numeric field="endingSeq" label="End Seq" v-slot="props">
                        {{ props.row.endingSeq }}
                    </b-table-column>    
                    <b-table-column numeric field="ticketsSold" label="Tickets Sold" v-slot="props">
                        {{ props.row.ticketsSold }}
                    </b-table-column>
                </b-table>

            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>

    </div>
</template>

<script>

import axios from 'axios'
// import moment from 'moment'
import UserStore from '../../../../../store/UserStore'
import DateRangeSelector from '../../../../../components/app/DateRangeSelector.vue'
import DataLoading from '../../../../../components/app/DataLoading.vue'
export default {
    metaInfo: {
        title: 'Lottery Sales Details'
    },

    components: {
        DateRangeSelector,
        DataLoading
    },

    data() {
        return {
            isPaginated: true,
            perPage: 30,
            isLoading: true,
            isFullPage: false,
            isScrollable: false,
            showDetailIcon: true,
            storeState: UserStore.getters.getStoreState,
            user: UserStore.getters.getUser,
            records: [],
            salesDateRange: [],

        }
    },

    methods: {
        fetchRecords() {
            if (this.user.activeStore) {
                this.isLoading = true
                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]                        

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/lottery/sales"
                    + "?from="+startDate+"&to="+endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data
                    
                    })
                    .catch( (err) => {
                        this.records = []
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

    },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.records = []
                this.fetchRecords()
            }
        }       

    },

    computed: {
        totalSalesAmount() {

            var totalSales = 0
            var bookSales = 0

            this.records.forEach(book => {
                book.lotteryBookSales.forEach(sales => {
                    sales.pricePoint = +(book.totalValue / book.ticketCount).toFixed(2)
                    sales.soldAmount = sales.pricePoint * sales.ticketsSold
                })
                bookSales = book.lotteryBookSales.reduce((sum, sales) => sum + sales.soldAmount,0,)
                totalSales += bookSales
            })

            return totalSales
        }
    },

    mounted() {
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]  
        // this.fetchRecords()
    }

    
}
</script>